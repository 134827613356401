import 'global/globals.css';
import 'swiper/swiper-bundle.min.css';
import { ThemeProvider } from '@xstyled/styled-components';
import theme from 'global/theme';

import Script from 'next/script';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'graphql/apollo-client';
import { appWithTranslation } from 'next-i18next';
import { Toaster } from 'react-hot-toast';
import { NextQueryParamProvider } from 'next-query-params';

import { FACEBOOK_PIXEL_ID } from 'global/constants';

import usePageView from 'lib/Google/usePageView';
//import FacebookChat from 'lib/Facebook/FacebookChat';
// import useAppRouting from 'hooks/useAppRouting';
import useAppVersion from 'hooks/useAppVersion';
import useCreateCart from 'hooks/cart/useCreateCart';
import useMergeCarts from 'hooks/cart/useMergeCarts';
import useCustomerState from 'hooks/account/useCustomerState';
import dynamic from 'next/dynamic';
//const FacebookChat = dynamic(() => import('lib/Facebook/FacebookChat'))

// import RouteLoader from '@/UI/Loader/RouteLoader';
import SEO from '@/SEO';

const Baltbaby = ({ Component, pageProps, router }) => {
  // const loading = useAppRouting();

  // Handle app version
  useAppVersion();

  // Handle logged-in customer data
  useCustomerState();

  // Handle cart ID in cookies & local storage
  useCreateCart();
  // Handle creating new cart & merging (when error occurs or user authenticated)
  useMergeCarts();

  // Initiate Google Tag Manager on every page
  usePageView();

  return (
    <>
      <SEO
        seo={{
          title: 'Baltbaby',
          description:
            'Baltbaby on baltikumi suurim beebitoodete e-kaubamaja. Baltbaby tootevalikust leiad suures koguses beebiriideid, vankreid ja muud beebi- ja lastekaupa.',
          image:
            'https://do.baltbaby.com/media/favicon/default/android-chrome-512x512.png'
        }}
      >
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta charSet="utf-8" />
        <meta
          name="google-site-verification"
          content="J2iCUPOj-OKEnd2vZt-L05cwV0xjl4l4gm3JoidUUXE"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://do.baltbaby.com/media/favicon/default/android-chrome-512x512.png"
        />
        <link
          href="/fonts/DMSans-Regular.ttf"
          as="font"
          type="font/ttf"
          rel="preload"
          crossOrigin="anonymous"
        />
        <link
          href="/fonts/DMSans-Medium.ttf"
          as="font"
          type="font/ttf"
          rel="preload"
          crossOrigin="anonymous"
        />
        <link
          href="/fonts/DMSans-Bold.ttf"
          as="font"
          type="font/ttf"
          rel="preload"
          crossOrigin="anonymous"
        />
      </SEO>
      
      {/* Mailchimp Script */}
      <Script
        id="mcjs"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            !function(c,h,i,m,p){
              m=c.createElement(h),p=c.getElementsByTagName(h)[0],
              m.async=1,m.src=i,p.parentNode.insertBefore(m,p)
            }(document,"script","https://chimpstatic.com/mcjs-connected/js/users/fbfe1f2d8cf768b124a3dc7aa/c00eff5303a29c3f1b3d21c6c.js");
          `
        }}
      />

      {/* Cookie Script */}
      <Script
        async
        defer
        strategy="afterInteractive"
        src="https://cdn.cookie-script.com/s/837247db125547f69e3a361dbcb25168.js"
      />
      <Script
        async
        defer
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-2E77N2LWHH"
      />
      <Script
        async
        defer
        id="GTM-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-2E77N2LWHH', {
            page_path: window.location.pathname
          });
          gtag('config', 'AW-751153594');`
        }}
      />
      {/* Facebook Pixel */}
      <Script
        async
        defer
        id="facebook-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${FACEBOOK_PIXEL_ID});
        fbq('track', 'PageView');
      `
        }}
      />
      <ThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <NextQueryParamProvider>
            <Toaster containerStyle={{ top: 80 }} />
            {/* {loading ? <RouteLoader /> : <Component {...pageProps} />} */}
            <Component {...pageProps} />
            {/* Facebook chat widget 
            <FacebookChat />
            */}
          </NextQueryParamProvider>
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
};

// export default appWithTranslation(Baltbaby);
export default appWithTranslation(Baltbaby);