import { gql } from '@apollo/client';

export const SEARCHED_PRODUCTS = gql`
  query GetProduct($name: String!, $sortBy: ProductAttributeSortInput, $pageSize: Int!, $currentPage: Int!) {
    products(search: $name, sort: $sortBy, pageSize: $pageSize, currentPage: $currentPage) {
      items {
        __typename
        name
        sku
        url_key
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        price {
          regularPrice {
            amount {
              value
            }
          }
        }
        image {
          url
          label
        }
        media_gallery {
          url
          label
        }
        ... on ConfigurableProduct {
          variants {
            attributes {
              uid
              code
              label
            }
            product {
              sku
              image {
                url
                label
              }
              media_gallery {
                url
                label
              }
            }
          }
        }
      }
      page_info {
        total_pages
        current_page
      }
      total_count
    }
  }
`;